import { PLATFORM } from './enums'
import BuildConfig from '../buildConfig'
import { getEnabledPlatforms } from '../data/enabledPlatforms'

const domainNames: Record<PLATFORM, string[]> = {
  [PLATFORM.FACEBOOK]: ['facebook'],
  [PLATFORM.GOOGLE]: ['google'],
  [PLATFORM.INSTAGRAM]: ['instagram'],
  [PLATFORM.LINKEDIN]: ['linkedin'],
  [PLATFORM.REDDIT]: ['reddit'],
  [PLATFORM.TIKTOK]: [],
  [PLATFORM.TWITTER]: ['twitter', 'x'],
  [PLATFORM.VENMO]: ['venmo'],
  [PLATFORM.SNAPCHAT]: [],
  [PLATFORM.STRAVA]: ['strava'],
  [PLATFORM.YOUTUBE]: ['youtube', 'google'],
}

const extensionServerHosts = [
  'https://*.privacypartyapp.com/*',
  'https://*.blockpartyapp.com/*',
]

export function getPlatformUrlsInHostPermissions(platform: string) {
  const urls: string[] = []

  const manifestPermissions =
    BuildConfig.BROWSER === 'chrome'
      ? chrome.runtime.getManifest().optional_host_permissions
      : chrome.runtime.getManifest().host_permissions

  for (const url of manifestPermissions) {
    for (const domainName of domainNames[platform]) {
      if (url.includes(`.${domainName}.`)) {
        urls.push(url)
      }
    }
  }

  return urls
}

export async function isHostPermissionPresent(url: string): Promise<boolean> {
  let hostUrl: URL
  try {
    // Prevent malformed URL strings such as URLs without a path (e.g. no
    // trailing /)
    hostUrl = new URL(url)
  } catch {
    return false
  }
  return chrome.permissions.contains({ origins: [hostUrl.href] })
}

export function getHostPermissionsObjectForPlatforms(
  platforms: PLATFORM[] | string[],
) {
  const hosts: string[] = []

  for (const platform of platforms) {
    hosts.push(...getPlatformUrlsInHostPermissions(platform))
  }

  return { origins: hosts }
}

export function getExtensionServerHostPermissionsObject() {
  const origins = [...extensionServerHosts]
  if (BuildConfig.REACT_APP_ENV === 'development') {
    origins.push('http://127.0.0.1/*')
  }
  return { origins }
}

export function isExtensionServerHostPermissionsPresent() {
  return chrome.permissions.contains(getExtensionServerHostPermissionsObject())
}

export async function promptForAllHostPermissions(
  onAccept: () => void,
  onDeny: () => void,
): Promise<void> {
  const hosts: string[] = []

  Object.keys(domainNames).forEach((platform) => {
    hosts.push(...getPlatformUrlsInHostPermissions(platform))
  })

  if (BuildConfig.REACT_APP_ENV === 'development') {
    hosts.push('http://127.0.0.1/*')
  }

  hosts.push(...extensionServerHosts)

  const allPermissions = {
    origins: hosts,
  }
  const response = await chrome.permissions.request(allPermissions)
  if (response) {
    return onAccept()
  }
  return onDeny()
}

export async function promptForExtensionServerPermissions(
  onAccept: () => void,
  onDeny: () => void,
): Promise<void> {
  const serverPermissions = getExtensionServerHostPermissionsObject()
  const response = await chrome.permissions.request(serverPermissions)
  if (response) {
    return onAccept()
  }
  return onDeny()
}

export async function checkForPlatformPermissions(
  platforms: PLATFORM[] | string[],
  includeExtensionServerPermissions = true,
): Promise<boolean> {
  const permissionsToBeChecked = getHostPermissionsObjectForPlatforms(platforms)

  // Add any additional host permissions
  if (includeExtensionServerPermissions) {
    permissionsToBeChecked.origins.push(...extensionServerHosts)
    if (BuildConfig.REACT_APP_ENV === 'development') {
      permissionsToBeChecked.origins.push('http://127.0.0.1/*')
    }
  }

  return chrome.permissions.contains(permissionsToBeChecked)
}

export async function getMissingPlatformHostPermissions(): Promise<string[]> {
  const missingPlatformPermissions: string[] = []

  for (const platform of Object.keys(domainNames)) {
    const permissionPresent = await checkForPlatformPermissions(
      [platform],
      false,
    )
    if (!permissionPresent) {
      missingPlatformPermissions.push(platform)
    }
  }

  return missingPlatformPermissions
}

export async function promptForPlatformPermissions(
  platforms: PLATFORM[],
  onAccept: () => void,
  onDeny: () => void,
  includeExtensionServerPermissions = true,
): Promise<void> {
  const permissionsToBeRequested =
    getHostPermissionsObjectForPlatforms(platforms)

  // Add any additional host permissions
  if (includeExtensionServerPermissions) {
    permissionsToBeRequested.origins.push(...extensionServerHosts)
    if (BuildConfig.REACT_APP_ENV === 'development') {
      permissionsToBeRequested.origins.push('http://127.0.0.1/*')
    }
  }

  const response = await chrome.permissions.request(permissionsToBeRequested)
  if (response) {
    return onAccept()
  }
  return onDeny()
}

async function isContentScriptRegisteredById(contentScriptId: string) {
  const registeredScripts = await chrome.scripting.getRegisteredContentScripts()

  if (registeredScripts) {
    return registeredScripts.find((script) => script.id === contentScriptId)
  }

  return false
}

async function registerContentScriptsForPlatform(platform: string) {
  if (BuildConfig.BROWSER !== 'firefox') {
    const contentScriptInjectDomId = `content-script-inject-dom-${platform}`
    const injectDomScriptRegistered = await isContentScriptRegisteredById(
      contentScriptInjectDomId,
    )
    const hostList = getPlatformUrlsInHostPermissions(platform)

    if (!injectDomScriptRegistered) {
      await chrome.scripting.registerContentScripts([
        {
          id: contentScriptInjectDomId,
          js: ['content-scripts/content-script-inject-dom.js'],
          css: ['content-scripts/content-script-inject-dom.css'],
          persistAcrossSessions: true,
          matches: hostList,
          runAt: 'document_start',
          world: 'ISOLATED',
        },
      ])
    }

    const contentScriptAutomationd = `content-script-automation-${platform}`
    const automationScriptRegistered = await isContentScriptRegisteredById(
      contentScriptAutomationd,
    )

    if (!automationScriptRegistered) {
      await chrome.scripting.registerContentScripts([
        {
          id: contentScriptAutomationd,
          js: ['content-scripts/content-script-automations.js'],
          persistAcrossSessions: true,
          matches: hostList,
          runAt: 'document_idle',
          world: 'ISOLATED',
        },
      ])
    }
  }

  return Promise.resolve()
}

export async function registerContentScriptsForAllowedPlatforms() {
  await Promise.all(
    getEnabledPlatforms().map(async (platform) => {
      const hostList = getPlatformUrlsInHostPermissions(platform)
      if (
        hostList &&
        hostList.length > 0 &&
        (await chrome.permissions.contains({ origins: hostList }))
      ) {
        return registerContentScriptsForPlatform(platform)
      }
      return Promise.resolve()
    }),
  )
}
