import { ApolloError } from '@apollo/client'
import omit from 'lodash/omit'

import {
  clearActiveTabId,
  getActiveTabId,
  getIsActiveTabStillOpen,
  setActiveTabId,
} from '$extensionSrc/data/activeTabId'

import { getCookieStoreFromTabId } from './cookies'
import {
  EXTENSION_MESSAGE_TYPES,
  MACHINE_STORAGE_KEY_SUFFIX,
  PLATFORM,
  STORAGE_KEY,
  TriggeringEventType,
} from './enums'
import * as localStorage from './localStorage'
import * as messages from './messages'
import { getHostPermissionsObjectForPlatforms } from './permissions'
import { getAndSetLoginStatus } from './platformUserData'
import { isValidPropertyString } from './stringValidator'
import { getActiveTab } from './tabs'
import { MeDocument } from '../__generated__/graphql'
import { LogInput } from '../__generated__/unauth/graphql'
import { getClient, isServerError } from '../apollo'
import { isEnabledPlatform } from '../data/enabledPlatforms'
import { getPlatformFromUrlString } from '../data/platform/platform'
import type { User } from '../data/user/user'
import {
  getAnonymousUserIdCookie,
  setAnonymousUserIdCookie,
} from '../metrics/cookies'

function sendMessage(message) {
  return chrome.runtime.sendMessage(message)
}

export function sendRefreshLoginStatus() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.REFRESH_LOGIN_STATUS,
    {},
  )
  return sendMessage(message)
}

export async function handleRefreshLoginStatus(message, sender, sendResponse) {
  if (sender.tab && sender.tab.id) {
    const platform = getPlatformFromUrlString(sender.tab.url)

    const store = await getCookieStoreFromTabId(sender.tab.id)

    if (store) {
      const success = await getAndSetLoginStatus(platform, store.id)
      sendResponse({ success })
    } else {
      // no cookie store found for tab
      sendResponse({ success: false })
    }
  }
}

export function changeUrl(url) {
  const message = messages.createMessage(EXTENSION_MESSAGE_TYPES.CHANGE_URL, {
    url,
  })
  return sendMessage(message)
}

export async function handleChangeUrlRequest(message, sender, sendResponse) {
  await chrome.tabs.update(sender.tab.id, { url: message.url })
  sendResponse({ success: true })
}

export function changeUrlAndWait(url) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.CHANGE_URL_AND_WAIT,
    {
      url,
    },
  )
  return sendMessage(message)
}

function navigateTabToUrlAndWait(tab, url) {
  chrome.tabs.update(tab.id, { url })
  return new Promise<void>((resolve) => {
    chrome.tabs.onUpdated.addListener(function onUpdated(tabId, info) {
      if (tabId === tab.id && info.status === 'complete') {
        chrome.tabs.onUpdated.removeListener(onUpdated)
        resolve()
      }
    })
  })
}

export async function handleChangeUrlRequestAndWait(
  message,
  sender,
  sendResponse,
) {
  await navigateTabToUrlAndWait(sender.tab, message.url)
  sendResponse({ success: true })
}

export function sendMinimizeSidebarToBackground(platform) {
  // Send MINIMIZE_SIDEBAR message from content script to background script
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SEND_MINIMIZE_SIDEBAR_TO_BACKGROUND,
    { platform },
  )
  return sendMessage(message)
}

export async function passOnMinimizeSidebarToTab(sender) {
  // Background script passes on original MINIMIZE_SIDEBAR message from content script
  // to content script in a specific tab
  const response = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.PASS_ON_MINIMIZE_SIDEBAR_TO_TAB,
    {},
  )
  await chrome.tabs.sendMessage(sender.tab.id, response)
}

export async function setMinimizeSidebar(platform, isMinimized) {
  if (!isEnabledPlatform(platform)) {
    throw new Error(`Invalid platform ${platform}`)
  }

  const minimizedByPlatform = await localStorage.getSingle(
    STORAGE_KEY.SIDEBAR_MINIMIZED,
  )

  return localStorage.set({
    [STORAGE_KEY.SIDEBAR_MINIMIZED]: {
      ...(minimizedByPlatform || {}),
      [platform]: Boolean(isMinimized),
    },
  })
}

export function handleMinimizeSidebar(message, sender, sendResponse) {
  return setMinimizeSidebar(message.platform, true)
    .then(() => passOnMinimizeSidebarToTab(sender))
    .then(() => sendResponse({ success: true }))
    .catch(() => sendResponse({ success: false }))
}

export function sendSidebarHeightToBackground(height) {
  // Send SIDEBAR_HEIGHT message from content script to background script
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SEND_SIDEBAR_HEIGHT_TO_BACKGROUND,
    { height },
  )
  return chrome.runtime.sendMessage(message)
}

export async function passOnSidebarHeightToTab(message, sender, sendResponse) {
  // Background script passes on original HIDE_SIDEBAR message from content script
  // to content script in a specific tab
  const height = message.height
  const response = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.PASS_ON_SIDEBAR_HEIGHT_TO_TAB,
    { height },
  )
  await chrome.tabs.sendMessage(sender.tab.id, response) // do I need to await here?

  return sendResponse({ success: true })
}

export function sendGetActiveTabForPlatform(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_ACTIVE_TAB,
    { platform },
  )

  return chrome.runtime.sendMessage(message)
}

export async function handleGetActiveTabForPlatform(
  { platform },
  sender,
  sendResponse,
) {
  // Query sender object to get tab id
  const tabId = sender.tab ? sender.tab.id : null

  // Query storage to get current active tab id
  const activeTabId = await getActiveTabId(platform)
  const activeTabStillOpen = await getIsActiveTabStillOpen(platform)

  // See if active tab is the current tab
  const [currentTab] = await chrome.tabs.query({
    active: true,
    lastFocusedWindow: true,
  })
  const isCurrentTab = currentTab && currentTab.id === tabId

  if (activeTabId) {
    try {
      return sendResponse({
        tabId,
        activeTabId,
        isCurrentTab,
        activeTabStillOpen,
      })
    } catch {
      return sendResponse({
        tabId,
        activeTabId,
        isCurrentTab,
        activeTabStillOpen,
      })
    }
  } else {
    return sendResponse({
      tabId,
      activeTabId,
      isCurrentTab,
      activeTabStillOpen: null,
    })
  }
}

export function sendGetCurrentTabUrl() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_CURRENT_TAB_URL,
    {},
  )

  return chrome.runtime.sendMessage(message)
}

export async function handleGetCurrentTabUrl(message, sender, sendResponse) {
  const currentTab = await getActiveTab()

  return sendResponse({ currentTabUrl: currentTab?.url })
}

export function sendSetActiveTabForPlatform(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_ACTIVE_TAB,
    { platform },
  )

  return chrome.runtime.sendMessage(message)
}

export async function handleSetActiveTabForPlatform(
  { platform },
  sender,
  sendResponse,
) {
  if (sender.tab && sender.tab.id) {
    await setActiveTabId(platform, sender.tab.id)
    sendResponse({ success: true })
  }

  return sendResponse({ success: false })
}

export function sendClearActiveTabForPlatform(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.CLEAR_ACTIVE_TAB,
    { platform },
  )

  return chrome.runtime.sendMessage(message)
}

export async function handleClearActiveTabForPlatform(
  { platform },
  sender,
  sendResponse,
) {
  await clearActiveTabId(platform)
  return sendResponse({ success: true })
}

// Interrupt currently executing automations
export function constructMachineSignalKey(machineName) {
  return machineName + MACHINE_STORAGE_KEY_SUFFIX.SIGNAL
}

export function sendSetMachineSignal(platform, signal) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_MACHINE_SIGNAL,
    { platform, signal },
  )

  return chrome.runtime.sendMessage(message)
}

export function sendSetAutomationNeedsUserInput(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_MACHINE_SIGNAL,
    { platform, signal: TriggeringEventType.ON_NEEDS_USER_INPUT },
  )
  return chrome.runtime.sendMessage(message)
}

export function sendSetAutomationPause(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_MACHINE_SIGNAL,
    { platform, signal: TriggeringEventType.ON_INPUT_PAUSE },
  )
  return chrome.runtime.sendMessage(message)
}

export function sendSetAutomationResume(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_MACHINE_SIGNAL,
    { platform, signal: TriggeringEventType.ON_INPUT_RESUME },
  )
  return chrome.runtime.sendMessage(message)
}

export function sendSetAutomationCancel(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_MACHINE_SIGNAL,
    { platform, signal: TriggeringEventType.ON_INPUT_CANCEL },
  )
  return chrome.runtime.sendMessage(message)
}

export function sendSetAutomationRetry(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_MACHINE_SIGNAL,
    { platform, signal: TriggeringEventType.ON_INPUT_RETRY },
  )
  return chrome.runtime.sendMessage(message)
}

export function handleSetMachineSignal(
  { platform, signal },
  sender,
  sendResponse,
) {
  const machineSignalKey = constructMachineSignalKey(platform)

  return localStorage
    .set({ [machineSignalKey]: signal })
    .then(() => sendResponse({ success: true }))
    .catch(() => sendResponse({ success: false }))
}

export async function getActiveTabLanguageRequestToBackground() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_ACTIVE_TAB_LANGUAGE_TO_BACKGROUND,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)
  return response.activeTabLanguage
}

export async function passOnGetActiveTabLanguageRequestToTab(
  message,
  sender: chrome.runtime.MessageSender,
  sendResponse,
) {
  if (!sender.tab?.id) {
    // eslint-disable-next-line no-console
    console.error('passOnGetActiveTabLanguageRequestToTab: No sender.tab.id')
    return undefined
  }
  const secondMessage = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.PASS_ON_GET_ACTIVE_TAB_LANGUAGE_TO_TAB,
    {},
  )
  const response = await chrome.tabs.sendMessage(sender.tab.id, secondMessage)
  if (!response?.activeTabLanguage) {
    // eslint-disable-next-line no-console
    console.error(
      'passOnGetActiveTabLanguageRequestToTab: No response.activeTabLanguage',
    )
    return undefined
  }
  return sendResponse({
    activeTabLanguage: response.activeTabLanguage,
  })
}

export function handleGetActiveTabLanguageRequest(
  message,
  sender,
  sendResponse,
) {
  // This gets the lang from the html attribute.
  // Does not use the chrome.tabs.detectLanguage method because that seems
  // different.
  return sendResponse({
    activeTabLanguage: document.documentElement.lang,
  })
}

export function sendCloseCurrentTab(): Promise<void> {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.CLOSE_CURRENT_TAB,
    {},
  )

  return chrome.runtime.sendMessage(message)
}

export async function handleCloseCurrentTab(message: {}, sender, sendResponse) {
  if (sender.tab?.id) {
    await chrome.tabs.remove(sender.tab.id)
  }
  sendResponse()
}

export function handleGetCurrentTab(message, sender, sendResponse) {
  return sendResponse({ tab: sender.tab })
}

async function setHideSidebar(platform, isHidden) {
  if (!platform || !isEnabledPlatform(platform)) {
    throw new Error(`Invalid platform ${platform}`)
  }

  const hiddenByPlatform = await localStorage.getSingle(
    STORAGE_KEY.SIDEBAR_HIDDEN,
  )

  return localStorage.set({
    [STORAGE_KEY.SIDEBAR_HIDDEN]: {
      ...(hiddenByPlatform || {}),
      [platform]: Boolean(isHidden),
    },
  })
}

export function sendHideSidebarForPlatform(platform) {
  const message = messages.createMessage(EXTENSION_MESSAGE_TYPES.HIDE_SIDEBAR, {
    platform,
  })

  return chrome.runtime.sendMessage(message)
}

export function handleHideSidebarForPlatform(message, sender, sendResponse) {
  return setHideSidebar(message.platform, true)
    .then(() => sendResponse({ success: true }))
    .catch(() => sendResponse({ success: false }))
}

export function sendUnhideSidebarForPlatform(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.UNHIDE_SIDEBAR,
    {
      platform,
    },
  )

  return chrome.runtime.sendMessage(message)
}

export function handleUnhideSidebarForPlatform(message, sender, sendResponse) {
  return setHideSidebar(message.platform, false)
    .then(() => sendResponse({ success: true }))
    .catch(() => sendResponse({ success: false }))
}

export function sendSetMetricsOptOut(isOptOut) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_METRICS_OPT_OUT,
    { isOptOut },
  )

  return chrome.runtime.sendMessage(message)
}

export function handleSetMetricsOptOut({ isOptOut }, sender, sendResponse) {
  return localStorage
    .set({ [STORAGE_KEY.METRICS_OPT_OUT]: Boolean(isOptOut) })
    .then(() => sendResponse({ success: true }))
    .catch(() => sendResponse({ success: false }))
}

export async function sendGetMetricsOptOut() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_METRICS_OPT_OUT,
    {},
  )
  const response = await chrome.runtime.sendMessage(message)

  return response.value
}

export function handleGetMetricsOptOut(message, sender, sendResponse) {
  return localStorage
    .getSingle(STORAGE_KEY.METRICS_OPT_OUT)
    .then((value) => sendResponse({ value }))
    .catch(() => sendResponse({ value: true }))
  // Default to opt out if there's an issue fetching value
}

export async function sendIsServiceWorkerReady() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.IS_SERVICE_WORKER_READY,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.value
}

export function handleIsServiceWorkerReady(message, sender, sendResponse) {
  return sendResponse({ value: true })
}

export async function sendShowModalOnCurrentTab() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SHOW_MODAL_ON_CURRENT_TAB,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.value
}

export async function handleShowModalOnCurrentTab(
  message,
  sender,
  sendResponse,
) {
  const senderTabId = sender.tab?.id ? `${sender.tab.id}` : null
  if (!senderTabId) {
    return sendResponse({ value: false })
  }

  const modalData = await localStorage.getSingle(
    STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL,
  )

  return sendResponse({ value: senderTabId in (modalData || {}) })
}

export async function sendGetModalForCurrentTab() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_MODAL_FOR_CURRENT_TAB,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response
}

export async function handleGetModalForCurrentTab(
  message,
  sender,
  sendResponse,
) {
  const defaultResponse = { selectedModalData: null, selectedModalType: null }

  const senderTabId = sender.tab?.id ? `${sender.tab.id}` : null
  if (!senderTabId) {
    return sendResponse(defaultResponse)
  }

  const modalData = await localStorage.getSingle(
    STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL,
  )

  if (!modalData || !modalData[senderTabId]) {
    return sendResponse(defaultResponse)
  }

  return sendResponse(modalData[senderTabId])
}

export async function sendCloseModalOnCurrentTab() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.CLOSE_MODAL_ON_CURRENT_TAB,
    {},
  )

  await chrome.runtime.sendMessage(message)
}

export async function handleCloseModalOnCurrentTab(
  message,
  sender,
  sendResponse,
) {
  const senderTabId = sender.tab?.id ? `${sender.tab.id}` : null
  if (!senderTabId) {
    return sendResponse()
  }

  const modalData = await localStorage.getSingle(
    STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL,
  )

  if (!modalData || !modalData[senderTabId]) {
    return sendResponse()
  }

  delete modalData[senderTabId]
  await localStorage.set({
    [STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL]: modalData,
  })

  return sendResponse()
}

export async function sendGetExtensionHostname(browser) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_EXTENSION_HOSTNAME,
    { browser },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.value
}

export function handleGetExtensionHostname(message, sender, sendResponse) {
  const { browser } = message
  const extensionPrefix =
    browser === 'firefox' ? 'moz-extension://' : 'chrome-extension://'
  sendResponse({ value: `${extensionPrefix}${chrome.runtime.id}` })
}

export async function sendGetLastExtensionErrorStackTrace() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_LAST_EXTENSION_ERROR_STACK_TRACE,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.value
}

export async function handleGetLastExtensionErrorStackTrace(
  message,
  sender,
  sendResponse,
) {
  const data = await chrome.storage.local.get(
    STORAGE_KEY.LAST_EXTENSION_ERROR_STACK_TRACE,
  )

  return sendResponse({
    value: data?.[STORAGE_KEY.LAST_EXTENSION_ERROR_STACK_TRACE],
  })
}

export async function sendSetLastExtensionErrorStackTrace(stackTrace) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_LAST_EXTENSION_ERROR_STACK_TRACE,
    { stackTrace },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.success
}

export async function handleSetLastExtensionErrorStackTrace(
  message,
  sender,
  sendResponse,
) {
  await chrome.storage.local.set({
    [STORAGE_KEY.LAST_EXTENSION_ERROR_STACK_TRACE]: message.stackTrace,
  })

  sendResponse({ success: true })
}

export async function sendGetLastFailedAutomationData() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_LAST_FAILED_AUTOMATION_DATA,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.data
}

export async function handleGetLastFailedAutomationData(
  message,
  sender,
  sendResponse,
) {
  const data = await chrome.storage.local.get(
    STORAGE_KEY.LAST_FAILED_AUTOMATION_DATA,
  )

  return sendResponse({
    data: data?.[STORAGE_KEY.LAST_FAILED_AUTOMATION_DATA],
  })
}

export async function sendSetLastFailedAutomationData(data) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_LAST_FAILED_AUTOMATION_DATA,
    { data },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.success
}

export async function handleSetLastFailedAutomationData(
  message,
  sender,
  sendResponse,
) {
  await chrome.storage.local.set({
    [STORAGE_KEY.LAST_FAILED_AUTOMATION_DATA]: message.data,
  })

  sendResponse({ success: true })
}

export async function sendGetTabId() {
  const message = messages.createMessage(EXTENSION_MESSAGE_TYPES.GET_TAB_ID, {})

  const response = await chrome.runtime.sendMessage(message)

  return response.tabId
}

export async function handleGetTabId(message, sender, sendResponse) {
  if (!sender.tab) {
    return sendResponse({ tabId: null })
  }
  return sendResponse({ tabId: sender.tab.id })
}

export async function sendGetCookieStoreIdFromTabId(): Promise<string | null> {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_COOKIE_STORE_ID_FROM_TAB_ID,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.storeId
}

export async function handleGetCookieStoreIdFromTabId(
  message,
  sender,
  sendResponse,
) {
  const senderTabId = sender?.tabId
  const activeTabId = await getActiveTab().then((tab) => tab?.id)

  if (!senderTabId && !activeTabId) {
    return sendResponse({ storeId: null })
  }

  const storeId = await getCookieStoreFromTabId(
    sender.tabId || activeTabId,
  ).then((store) => store?.id)
  return sendResponse({ storeId })
}

export async function sendGetIsTabStillOpen(tabId) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_IS_TAB_STILL_OPEN,
    { tabId },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.isTabStillOpen
}

export async function handleGetIsTabStillOpen(message, sender, sendResponse) {
  if (message.tabId) {
    try {
      const tab = await chrome.tabs.get(message.tabId)
      return sendResponse({ isTabStillOpen: tab !== null })
    } catch (error) {
      return sendResponse({ isTabStillOpen: false })
    }
  }

  return null
  // if no tab id, do not try to send response due to no tab with id error
}

export async function sendGetNotificationData() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_NOTIFICATION_DATA,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.notificationData
}

export async function handleGetNotificationData(message, sender, sendResponse) {
  const notificationDataItems = await chrome.storage.local.get([
    STORAGE_KEY.NOTIFICATION_DATA,
  ])

  const currentNotifications =
    notificationDataItems?.[STORAGE_KEY.NOTIFICATION_DATA] || {}

  return sendResponse({ notificationData: currentNotifications })
}

type GetCurrentUserMessage = {
  __messageType__: 'GET_CURRENT_USER'
}

export type GetCurrentUserResponse = {
  data?: User | null
  error?: string
}

export async function sendGetCurrentUser(): Promise<GetCurrentUserResponse> {
  const message: GetCurrentUserMessage = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.GET_CURRENT_USER,
    {},
  )
  return chrome.runtime.sendMessage(message)
}

export async function handleGetCurrentUser(
  _message: GetCurrentUserMessage,
  _sender: chrome.runtime.MessageSender,
  sendResponse: (response: GetCurrentUserResponse) => void,
) {
  try {
    const response = await getClient().query({
      fetchPolicy: 'no-cache',
      query: MeDocument,
    })
    // In development, response.data.me will be null if a user isn't logged
    // in
    if (!response.data.me) {
      sendResponse({ data: null })
    } else {
      /**
       * We currently specify trialPlatform as a string, which we want to
       * parse as a PLATFORM enum to ease downstream use.
       *
       * Ideally, PLATFORM would be an Enum in the GraphQL schema so we
       * could easily share that across the extension and the server.
       */
      const trialPlatformName = response.data.me.trialPlatform
      const trialPlatform = trialPlatformName
        ? PLATFORM[trialPlatformName]
        : null

      sendResponse({
        data: {
          ...response.data.me,
          autoscanSettings: response.data.me.autoscanSettings.map(
            (setting) => ({
              enabled: setting.enabled,
              platform: setting.platform as PLATFORM,
              platformUserId: setting.platformUserId,
            }),
          ),
          trialPlatform,
        },
      })
    }
  } catch (e) {
    const error: ApolloError = e
    if (
      isServerError(error.networkError) &&
      error.networkError.statusCode === 401
    ) {
      // In production, POST /api/graphql will return 401 if a user isn't
      // logged in
      sendResponse({ data: null })
      return
    }
    sendResponse({ error: `handleGetCurrentUser: ${e.message}` })
  }
}

export async function sendClearIconBadge() {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.CLEAR_ICON_BADGE,
    {},
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.success
}

export async function handleClearIconBadge(message, sender, sendResponse) {
  chrome.action.setBadgeText({
    text: '',
  })

  return sendResponse({ success: true })
}

export async function sendSetIconBadge(text) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SET_ICON_BADGE,
    { text },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response.success
}

export async function handleSetIconBadge(message, sender, sendResponse) {
  chrome.action.setBadgeText({
    text: message.text,
  })

  return sendResponse({ success: true })
}

export async function sendHasPermissionsForPlatform(platform) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.HAS_PERMISSIONS_FOR_PLATFORM,
    { platform },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response?.hasPermissions
}

export async function handleHasPermissionsForPlatform(
  message,
  sender,
  sendResponse,
) {
  const { platform } = message

  if (!platform || !isEnabledPlatform(platform)) {
    return sendResponse({})
  }

  const foundOrigins = (await chrome.permissions.getAll()).origins
  const requiredOrigins = getHostPermissionsObjectForPlatforms([
    platform,
  ]).origins

  const hasPermissions = requiredOrigins.every((origin) =>
    foundOrigins?.includes(origin),
  )

  return sendResponse({ hasPermissions })
}

export async function setLastSelectedAccount({
  platform,
  platformUserId,
  platformUsername,
}: {
  platform: PLATFORM
  platformUserId: string | null
  platformUsername: string | null
}) {
  if (!isEnabledPlatform(platform)) {
    throw new Error(`setLastSelectedAccount: Invalid platform ${platform}`)
  }

  const lastSelectedAccount = await localStorage.getSingle(
    STORAGE_KEY.LAST_SELECTED_ACCOUNT,
  )

  if (platformUserId === null) {
    if (!lastSelectedAccount) {
      return undefined
    }
    return localStorage.set({
      [STORAGE_KEY.LAST_SELECTED_ACCOUNT]: omit(
        lastSelectedAccount || {},
        platform,
      ),
    })
  }

  if (
    !isValidPropertyString(platformUserId) ||
    !isValidPropertyString(platformUsername)
  ) {
    throw new Error(
      `setLastSelectedAccount: Invalid platform user ID ${platformUserId} ` +
        `or platform user name ${platformUsername}`,
    )
  }

  return localStorage.set({
    [STORAGE_KEY.LAST_SELECTED_ACCOUNT]: {
      ...(lastSelectedAccount || {}),
      [platform]: {
        platformUserId,
        platformUsername,
      },
    },
  })
}

export async function sendAlertNotification(
  platform: string,
  title: string,
  content: string,
  url?: string,
) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.SEND_ALERT_NOTIFICATION,
    { platform, title, content, url },
  )

  return chrome.runtime.sendMessage(message)
}

export async function handleGetAnonymousUserId(message, sender, sendResponse) {
  const anonymousUserId = await getAnonymousUserIdCookie()
  return sendResponse({ anonymousUserId })
}

export async function handleSetAnonymousUserId(message, sender, sendResponse) {
  await setAnonymousUserIdCookie(message.anonymousUserId)
  return sendResponse({ success: true })
}

export async function sendRemoteLog(logInput: LogInput): Promise<void> {
  const message = messages.createMessage(EXTENSION_MESSAGE_TYPES.REMOTE_LOG, {
    logInput,
  })
  return chrome.runtime.sendMessage(message)
}
