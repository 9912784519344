import React from 'react'

import { useRequest } from 'ahooks'
import clsx from 'clsx'

import { dayInMs } from '$extensionSrc/utils/dateUtils'

import styles from './MetricsKpis.module.scss'
import { getIsoDateString } from '../utils/dateHelpers'
import {
  getBulkRequestUrlForKey,
  ResponseJsonType,
} from '../utils/metricsApiRequests'

type MetricsBoxInput = {
  title: string
  value: number
  lastUpdatedOn: string
}

function MetricsBox({ title, value, lastUpdatedOn }: MetricsBoxInput) {
  const olderThanOneDay =
    new Date().setHours(0, 0, 0, 0) -
      new Date(lastUpdatedOn).setHours(0, 0, 0, 0) >
    dayInMs

  return (
    <div className={styles.MetricsBox}>
      <h2>{title}</h2>
      <div>
        <span className={clsx('display2', olderThanOneDay && 'red')}>
          <span className="white">{olderThanOneDay && '*'}</span>
          {value}
          <span>{olderThanOneDay && '*'}</span>
        </span>
      </div>
      <div className={clsx('body3', olderThanOneDay && 'red')}>
        Last updated on: <br />{' '}
        {lastUpdatedOn ? getIsoDateString(new Date(lastUpdatedOn)) : '(null)'}
      </div>
    </div>
  )
}

export default function MetricsKpis() {
  const dataKeys = [
    'installs_chrome',
    'installs_firefox',
    'installs_edge',
    'n_signups_completed',
    'n_trials_created',
    'n_plans_created',
  ]

  const { data, loading } = useRequest(async () => {
    const response = await fetch(
      getBulkRequestUrlForKey(dataKeys, {
        startDateAsStr: '2023-01-01',
        endDateAsStr: getIsoDateString(new Date()),
      }),
    )

    if (response.status === 200) {
      const tsdata: ResponseJsonType = await response.json()

      // Aggregate all totals
      const totals: { [key: string]: number } = {}
      for (const key of dataKeys) {
        totals[key] = tsdata[key].timeseries.reduce(
          (acc, { value }) => acc + value,
          0,
        )
      }

      const lastUpdatedOn: { [key: string]: string } = {}
      for (const key of dataKeys) {
        lastUpdatedOn[key] = tsdata[key].lastUpdatedOn
      }

      return { totals, lastUpdatedOn }
    }
    throw new Error(`Failed to fetch data: ${response.statusText}`)
  })

  if (loading) {
    return <div>Loading...</div>
  }

  const totalInstalls =
    (data?.totals.installs_chrome ?? 0) +
    (data?.totals.installs_firefox ?? 0) +
    (data?.totals.installs_edge ?? 0)

  const inputs: MetricsBoxInput[] = [
    {
      title: 'Total Installs',
      value: totalInstalls,
      lastUpdatedOn: data?.lastUpdatedOn.installs_chrome,
    },
    {
      title: 'Signups',
      value: data?.totals.n_signups_completed,
      lastUpdatedOn: data?.lastUpdatedOn.n_signups_completed,
    },
    {
      title: 'Trials',
      value: data?.totals.n_trials_created,
      lastUpdatedOn: data?.lastUpdatedOn.n_trials_created,
    },
    {
      title: 'Plans',
      value: data?.totals.n_plans_created,
      lastUpdatedOn: data?.lastUpdatedOn.n_plans_created,
    },
  ]

  return (
    <div className={styles.MetricsKpis}>
      <h1>KPIs (since 2023-01-01)</h1>
      <div className={styles.stats}>
        {inputs.map((input) => (
          <MetricsBox key={input.title} {...input} />
        ))}
      </div>
    </div>
  )
}
