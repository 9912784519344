import { useRequest } from 'ahooks'

import * as canIUse from '$extensionSrc/utils/canIUse'
import { PLATFORM, STORAGE_KEY } from '$extensionSrc/utils/enums'
import * as localStorage from '$extensionSrc/utils/localStorage'

export async function setOverlayScrollPosition(
  platform: PLATFORM,
  scrollPosition: number,
): Promise<void> {
  if (!canIUse.chromeStorage()) {
    return undefined
  }

  const currentOverlayScrollPosition = await localStorage.getSingle(
    STORAGE_KEY.OVERLAY_SCROLL_POSITION,
  )

  return chrome.storage.local.set({
    [STORAGE_KEY.OVERLAY_SCROLL_POSITION]: {
      ...(currentOverlayScrollPosition || {}),
      [platform]: scrollPosition,
    },
  })
}

export function useInitialOverlayScrollPosition(
  platform: PLATFORM,
): number | undefined {
  const { data, loading } = useRequest(() =>
    localStorage.getSingle(STORAGE_KEY.OVERLAY_SCROLL_POSITION),
  )

  if (!platform || loading || !data) {
    // Still loading
    return undefined
  }

  return data[platform] || 0
}
