import { isEnabledPlatform } from './data/enabledPlatforms'
import {
  NotificationStatus,
  NotificationType,
} from './notifications/notificationTypes'
import { StoredRecommendationProgress } from './recommendations/recommendationProgressTypes'
import { getSpecForPlatformRecommendation } from './recommendations/recommendationRegistryApi'
import { RecommendationKeyEnum } from './recommendations/recommendationTypes'
import { MACHINE_STORAGE_KEY_SUFFIX, STORAGE_KEY } from './utils/enums'
import * as localStorage from './utils/localStorage'
import { isValidPropertyString } from './utils/stringValidator'

export function clearState() {
  return localStorage.remove(Object.keys(STORAGE_KEY))
}

export async function getReccProgressByPlatformAccount(
  platform: string,
  platformUserId: string,
): Promise<{ [key: string]: any }> {
  if (!isEnabledPlatform(platform) || !isValidPropertyString(platformUserId)) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${platformUserId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.RECC_PROGRESS)
  if (blob && blob[platform] && blob[platform][platformUserId]) {
    return blob[platform][platformUserId]
  }
  return {}
}

export async function setReccProgressByPlatformAccount(
  platform: string,
  platformUserId: string,
  data: { [key: string]: StoredRecommendationProgress },
): Promise<void> {
  if (!isEnabledPlatform(platform) || !isValidPropertyString(platformUserId)) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${platformUserId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.RECC_PROGRESS)
  const newBlob = structuredClone(blob || {})

  if (!(platform in newBlob)) {
    newBlob[platform] = {}
  }

  newBlob[platform][platformUserId] = data

  return localStorage.set({ [STORAGE_KEY.RECC_PROGRESS]: newBlob })
}

async function updateMultipleRecommendations(
  platform: string,
  platformUserId: string,
  recommendationKeys: string[],
  newData: { [key: string]: any },
  options: { delete?: boolean } = {},
) {
  const progressBlob = await getReccProgressByPlatformAccount(
    platform,
    platformUserId,
  )

  const updatedProgressBlob = { ...progressBlob }

  for (const recommendationKey of recommendationKeys) {
    if (isValidPropertyString(recommendationKey)) {
      if (options.delete) {
        updatedProgressBlob[recommendationKey] = {
          initialState: null,
          automationErrors: null,
          isSkipped: false,
          isUpdated: false,
          lastGatheredTimestamp: null,
          settingsSelected: {},
          isDeleted: true,
        }
      } else {
        updatedProgressBlob[recommendationKey] = {
          ...(updatedProgressBlob?.[recommendationKey] || {}),
          ...newData,
          isDeleted: false,
        }
      }

      updatedProgressBlob[recommendationKey].lastUpdatedTimestamp = Date.now()
    }
  }

  return setReccProgressByPlatformAccount(
    platform,
    platformUserId,
    updatedProgressBlob,
  )
}

export async function updateRecommendation(
  platform: string,
  platformUserId: string,
  recommendationKey: RecommendationKeyEnum,
  newData: { [key: string]: any },
  options: {
    delete?: boolean
    doNotUpdateLastUpdatedTimestamp?: boolean
  } = {},
): Promise<void> {
  if (!isValidPropertyString(recommendationKey)) {
    throw new Error(`Invalid recommendation key ${recommendationKey}`)
  }

  const progressBlob = await getReccProgressByPlatformAccount(
    platform,
    platformUserId,
  )

  const recommendationSpec = getSpecForPlatformRecommendation(
    platform,
    recommendationKey,
  )
  const defaultProgress = {
    initialState: null,
    automationErrors: null,
    isFulfilled: false,
    isSkipped: false,
    isUpdated: false,
    lastGatheredTimestamp: null,
    settingsSelected: recommendationSpec.settings
      ? Object.fromEntries(
          recommendationSpec.settings.map((setting) => [setting.key, true]),
        )
      : null,
  }

  const updatedProgressBlob = { ...progressBlob }

  if (options.delete) {
    updatedProgressBlob[recommendationKey] = {
      initialState: null,
      automationErrors: null,
      isFulfilled: false,
      isSkipped: false,
      isUpdated: false,
      lastGatheredTimestamp: null,
      settingsSelected: {},
      isDeleted: true,
    }
  } else {
    updatedProgressBlob[recommendationKey] = {
      ...defaultProgress,
      ...(updatedProgressBlob?.[recommendationKey] || {}),
      ...newData,
      isDeleted: false,
    }
  }

  if (!options?.doNotUpdateLastUpdatedTimestamp) {
    updatedProgressBlob[recommendationKey].lastUpdatedTimestamp = Date.now()
  }

  return setReccProgressByPlatformAccount(
    platform,
    platformUserId,
    updatedProgressBlob,
  )
}

export function updateSettingsSelected(
  platform: string,
  platformUserId: string,
  recommendationKey: string,
  data: { [key: string]: boolean },
): Promise<void> {
  return updateRecommendation(
    platform,
    platformUserId,
    recommendationKey as RecommendationKeyEnum,
    {
      settingsSelected: data,
    },
  )
}

export function setRecommendationStatus(
  platform: string,
  platformUserId: string,
  recommendationKey: string,
  changeType: {
    updated?: boolean
    undoUpdated?: boolean
    skipped?: boolean
    undoSkipped?: boolean
  },
): Promise<void> {
  if (Object.values(changeType).filter((x) => x).length !== 1) {
    throw new Error('Exactly one of changeType must be set to true')
  }

  let statusBlob
  if (changeType.updated) {
    statusBlob = { isUpdated: true }
  } else if (changeType.undoUpdated) {
    statusBlob = { isUpdated: false }
  } else if (changeType.skipped) {
    statusBlob = { isSkipped: true }
  } else if (changeType.undoSkipped) {
    statusBlob = { isSkipped: false }
  } else {
    throw new Error('Invalid status')
  }

  return updateRecommendation(
    platform,
    platformUserId,
    recommendationKey as RecommendationKeyEnum,
    statusBlob,
  )
}

export function setMultipleRecommendationStatuses(
  platform: string,
  platformUserId: string,
  recommendationKeys: string[],
  changeType: {
    updated?: boolean
    undoUpdated?: boolean
    skipped?: boolean
    undoSkipped?: boolean
  },
): Promise<void> {
  if (Object.values(changeType).filter((x) => x).length !== 1) {
    throw new Error('Exactly one of changeType must be set to true')
  }

  let statusBlob
  if (changeType.updated) {
    statusBlob = { isUpdated: true }
  } else if (changeType.undoUpdated) {
    statusBlob = { isUpdated: false }
  } else if (changeType.skipped) {
    statusBlob = { isSkipped: true }
  } else if (changeType.undoSkipped) {
    statusBlob = { isSkipped: false }
  } else {
    throw new Error('Invalid status')
  }

  return updateMultipleRecommendations(
    platform,
    platformUserId,
    recommendationKeys,
    statusBlob,
  )
}

export async function setRecommendationTimestamp(
  platform,
  platformUserId,
  recommendationKey,
) {
  await updateRecommendation(platform, platformUserId, recommendationKey, {
    lastGatheredTimestamp: Date.now(),
  })
}

export async function setSetupProgressCompleted(
  platform: string,
  userId: string,
): Promise<void> {
  if (!isEnabledPlatform(platform) || !isValidPropertyString(userId)) {
    throw new Error(
      `Invalid platform ${platform} or platform user id ${userId}`,
    )
  }

  const blob = await localStorage.getSingle(STORAGE_KEY.SETUP_PROGRESS)

  const updatedBlob = structuredClone(blob || {})

  if (!(platform in updatedBlob)) {
    updatedBlob[platform] = {}
  }

  if (!(userId in updatedBlob[platform])) {
    updatedBlob[platform][userId] = {}
  }

  updatedBlob[platform][userId].isCompleted = true
  updatedBlob[platform][userId].lastUpdatedTimestamp = Date.now()

  return localStorage.set({ [STORAGE_KEY.SETUP_PROGRESS]: updatedBlob })
}

export function getFirstTimeUserModalDismissed() {
  return localStorage
    .getSingle(STORAGE_KEY.FIRST_TIME_USER_MODAL_DISMISSED)
    .then((dismissed) => dismissed)
}

export function setFirstTimeUserModalDismissed(dismissed) {
  localStorage.set({ [STORAGE_KEY.FIRST_TIME_USER_MODAL_DISMISSED]: dismissed })
}

export function getDashboardPermissionModalClosed() {
  return localStorage
    .getSingle(STORAGE_KEY.DASHBOARD_PERMISSION_HEADER_CLOSED)
    .then((closed) => closed)
}

export function setDashboardPermissionModalClosed(closed) {
  return localStorage.set({
    [STORAGE_KEY.DASHBOARD_PERMISSION_HEADER_CLOSED]: closed,
  })
}

export function getDebugData() {
  return localStorage
    .getSingle(STORAGE_KEY.DEBUG_DATA)
    .then((debugData) => debugData)
}

export function setDebugData(debugData) {
  localStorage.set({ [STORAGE_KEY.DEBUG_DATA]: debugData })
}

export function constructMachineContextKey(machineName) {
  return machineName + MACHINE_STORAGE_KEY_SUFFIX.CONTEXT
}

export function getMachineContext(platform) {
  const machineContextKey = constructMachineContextKey(platform)
  return localStorage.getSingle(machineContextKey)
}

export function setMachineContext(platform, context) {
  const machineContextKey = constructMachineContextKey(platform)

  return localStorage.set({ [machineContextKey]: context })
}

export function updateMachineContext(platform, context) {
  const machineContextKey = constructMachineContextKey(platform)

  return localStorage.getSingle(machineContextKey).then((prevContext) =>
    localStorage.set({
      [machineContextKey]: {
        ...(prevContext || {}),
        ...context,
      },
    }),
  )
}

export async function initializeNotificationSettings() {
  const blob = Object.fromEntries(
    Object.values(NotificationType).map((key) => [key, true]),
  )
  await localStorage.set({ [STORAGE_KEY.NOTIFICATION_SETTINGS]: blob })
}

export async function updateNotificationSetting(notificationType, value) {
  const blob = await localStorage.getSingle(STORAGE_KEY.NOTIFICATION_SETTINGS)
  blob[notificationType] = value
  await localStorage.set({ [STORAGE_KEY.NOTIFICATION_SETTINGS]: blob })
}

export async function updateNotificationStatus(notificationId, status) {
  const currentNotifications = await localStorage.getSingle(
    STORAGE_KEY.NOTIFICATION_DATA,
  )

  if (notificationId in currentNotifications) {
    currentNotifications[notificationId].status = status

    await localStorage.set({
      [STORAGE_KEY.NOTIFICATION_DATA]: currentNotifications,
    })
  }
}

export async function updateAllNewNotificationStatusAsSeen(notificationData) {
  const currentNotifications: { [id: string]: { status: NotificationStatus } } =
    await localStorage.getSingle(STORAGE_KEY.NOTIFICATION_DATA)

  Object.entries(currentNotifications).forEach(([id, notification]) => {
    if (
      notification.status === NotificationStatus.NEW &&
      id in notificationData
    ) {
      notification.status = NotificationStatus.SEEN // eslint-disable-line no-param-reassign
    }
  })

  return localStorage.set({
    [STORAGE_KEY.NOTIFICATION_DATA]: currentNotifications,
  })
}
