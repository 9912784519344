export type Changes = { [key: string]: chrome.storage.StorageChange }

type ChangesCallbackFunction = (changes: Changes) => void

export function get(
  k_or_ks: string | string[],
): Promise<{ [key: string]: any }> {
  return chrome.storage && chrome.storage.local.get(k_or_ks)
}

export function getSingle(k: string): Promise<any> {
  return get(k).then((o) => o[k])
}

export function getAndUseSingle(k: string, callback: (result: any) => void) {
  if (chrome.storage) {
    getSingle(k).then((result) => callback(result))
  }
}

export function set(o: object): Promise<void> {
  return chrome.storage && chrome.storage.local.set(o)
}

export function remove(k_or_ks: string | string[]): Promise<void> {
  return chrome.storage && chrome.storage.local.remove(k_or_ks)
}

export function setupListener(callback: ChangesCallbackFunction): void {
  return (
    chrome.storage &&
    chrome.storage.onChanged.addListener((changes, areaName) => {
      if (areaName === 'local') {
        callback(changes)
      }
    })
  )
}

export function removeListener(callback: ChangesCallbackFunction): void {
  return (
    chrome.storage &&
    chrome.storage.onChanged.removeListener((changes, areaName) => {
      if (areaName === 'local') {
        callback(changes)
      }
    })
  )
}

export function initIfNotExists(initObject: object): Promise<void> {
  const keys = Object.keys(initObject)

  return get(keys).then((o) => {
    const undefinedEntries = Object.entries(initObject).filter(
      ([k]) => !(k in o),
    )
    return set(Object.fromEntries(undefinedEntries))
  })
}
